import React from 'react';
import ReactDOM from 'react-dom/client';
import './Global/Reset.css';
import './Global/Style.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { AllContexts } from './Contexts/GlobalContext';
import TagManager from 'react-gtm-module';


const tagManagerArgs = {
  gtmId: 'GTM-MXZG3NLD',
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AllContexts>
        <App />
      </AllContexts>
    </BrowserRouter>
  </React.StrictMode>
);

// Medir desempenho, se necessário
reportWebVitals();



// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './Global/Reset.css';

// import './Global/Style.css';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import { BrowserRouter } from 'react-router-dom';
// import { AllContexts } from './Contexts/GlobalContext';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <AllContexts>
//         <App />
//       </AllContexts>
//     </BrowserRouter>
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
